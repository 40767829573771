import { getLocalStorage } from '../hooks/appVariables'
import useStore from '../store'

export const getUserId = () => {
    const user = getLocalStorage(process.env.REACT_APP_USER_KEY)
    let userId = 0
    if (user) {
        userId = user.id
    } else {
        userId = useStore.getState().user.id
    }
    return userId
}