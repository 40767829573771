import { fetchUserAttributes } from 'aws-amplify/auth';

export const FetchAuthenticatedUser = async () => {
    try {
        const response = await fetchUserAttributes();
        return response.email;
    } catch (error) {
        console.log('userAttributes error: ', error);
        return '';
    }
}