
import { act, useState } from "react";
import { Button, Col, Container, Form, Input, Label, Modal, ModalHeader, Row, Table } from "reactstrap";
import { useTheme } from "../../../hooks/useTheme";
import BannerEditor from "./bannerEditor";

const EditWelcomeModal = () => {
    const theme = useTheme()
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)
    const [modalData, setModalData] = useState(null);

    const data = [
        {
            title: "Welcome Modal",
            html: "<div><h1>Welcome to our site</h1><p>Click here to see our latest offers</p></div>",
            active: true
        },
        {
            title: "Welcome Modal",
            html: "<div><h1>Welcome to our site</h1><p>Click here to see our latest offers</p></div>",
            active: false
        },
        {
            title: "Welcome Modal",
            html: "<div><h1>Welcome to our site</h1><p>Click here to see our latest offers</p></div>",
            active: false
        }]

    const handleEdit = (data) => {
        setModalData(data);
        toggle();
    }

    return (
        <>
            <Container fluid className='px-4 pb-2'>
                <Row className="align-items-center justify-content-between">
                    <Col lg={7} className='my-1'>
                        <Form>
                            <Label className="visually-hidden" for="search">Search</Label>
                            <Input
                                type="search"
                                id="search"
                                data-testid="admin-users-search"
                                name="search"
                                placeholder="Search..."
                            />
                        </Form>
                    </Col>

                    <Col lg={2} className='my-1 text-right'>
                        <Button color="primary" className='float-right' onClick={toggle}>Add New</Button>
                    </Col>
                </Row>

                <br />

                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>HTML</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.title}</td>
                                <td>{item.html}</td>
                                <td className="text-right">
                                    <div className="d-flex justify-content-end">
                                        {/* Show Activate button only if not active */}
                                        <Button color={theme === "dark-mode" ? "dark" : "light"} outline>Preview</Button>
                                        &nbsp;
                                        {!item.active ? (
                                            <Button color={theme === "dark-mode" ? "dark" : "light"} outline>
                                                Activate
                                            </Button>
                                        ) : (
                                            <Button color="success" disabled>
                                                Active
                                            </Button>
                                        )}
                                        &nbsp;
                                        <Button color="primary" onClick={() => handleEdit(item)}>Edit</Button>
                                        &nbsp;
                                        {/* Show Delete button only if not active */}
                                        {!item.active && <Button color="danger">Delete</Button>}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

            <Modal isOpen={modal} toggle={toggle} size="xl" centered={true} backdrop="static" data={data} >
                <ModalHeader toggle={toggle}>Banner Editor</ModalHeader>
                <BannerEditor />
            </Modal>

        </>
    )
}

export default EditWelcomeModal;