/**************************************************************
 * 4/4/24 - RGM
 * 
 * This method is used when the company is changed so that if 
 * there is a page with an id of a specific object that would 
 * fail when changing companies, it redirects to the closest 
 * section.
**************************************************************/

export const getRedirectUrl = () => {
    let lRoute = window.location.href
    if (lRoute.indexOf("/tickets/") > -1 && !lRoute.endsWith("alltickets")) {
        lRoute = lRoute.substring(0, lRoute.indexOf("/tickets/")) + "/tickets/alltickets";
    } else if (lRoute.indexOf("/quotes-and-projects/projects/") > -1) {
        lRoute = lRoute.substring(0, lRoute.indexOf("/quotes-and-projects/projects/")) + "/quotes-and-projects/projects";
    } else if (lRoute.indexOf("/quotes-and-projects/quotes/") > -1) {
        lRoute = lRoute.substring(0, lRoute.indexOf("/quotes-and-projects/quotes/")) + "/quotes-and-projects/quotes";
    } else if (lRoute.indexOf("/plannedmaintenance/") > -1 && !lRoute.endsWith("plannedmaintenance")) {
        lRoute = lRoute.substring(0, lRoute.indexOf("/plannedmaintenance/")) + "/plannedmaintenance";
    } else if (lRoute.indexOf("/uca-management-user/") > -1) {
        lRoute = lRoute.substring(0, lRoute.indexOf("/uca-management-user/")) + "/uca-management";
    } else if (lRoute.indexOf("/uca-management-company/") > -1) {
        lRoute = lRoute.substring(0, lRoute.indexOf("/uca-management-company/")) + "/uca-management";
    } else if (lRoute.indexOf("/admin/user-management/") > -1) {
        lRoute = lRoute.substring(0, lRoute.indexOf("/admin/user-management/")) + "/admin/user-management/";
    } else if (lRoute.indexOf("/sites/") > -1 && !lRoute.endsWith("allsites")) {
        lRoute = lRoute.substring(0, lRoute.indexOf("/sites/")) + "/sites";
    } else if (lRoute.indexOf("/admin/company-management/") > -1) {
        lRoute = lRoute.substring(0, lRoute.indexOf("company-management/")) + 'company-management';
    } else {
        // Fallback URL if none of the conditions are met
        lRoute = "/dashboard";
    }

    return lRoute
}
