import { Button, Card, CardBody, Col, Container, Modal, ModalHeader, Row } from "reactstrap";
import { useTheme } from "../../hooks/useTheme";
import { useState } from "react";
import EditWelcomeModal from "../../features/content-manager/components/EditWelcomeModal";

const ContentManager = () => {
    const theme = useTheme()

    const [currentActiveTab, setCurrentActiveTab] = useState("1")
    const toggleTab = (tab) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab)
    }


    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div className="container-fluid pt-2">
                                <h1 data-testid="customer-ticket-title" className="display-5 fw-bold px-2 mb-0">Manage your users</h1>

                                <p data-testid="customer-ticket-subtitle" className="lead px-2">Create, edit and manage users within your organisation.</p>
                            </div>
                            <Row>
                                <Container fluid>
                                    <Row>
                                        <Col style={{ display: 'inline-flex' }}>
                                            <h6 data-testid="viewproject-details-tab" className={currentActiveTab === "1" ? "custom-nav-selected custom-nav m-3" : "custom-nav m-3"} style={{ cursor: 'pointer' }}
                                                onClick={() => { toggleTab("1"); }}
                                            >Welcome Modal</h6>
                                        </Col>
                                    </Row>
                                    {currentActiveTab === "1" && (
                                        <EditWelcomeModal />
                                    )}
                                </Container>
                            </Row>
                        </CardBody>
                        
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ContentManager;