import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useConfirmation } from '../hooks/useConfirmation';
import { useTheme } from '../hooks/useTheme';

const ConfirmationDialog = () => {
  const theme = useTheme()
  const { isVisible, message, handleConfirm, handleCancel } = useConfirmation();

  return (
    <Modal isOpen={isVisible} toggle={handleCancel} centered>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color={theme === "dark-mode" ? "dark" : "light"} outline onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationDialog;
