import { useState, useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getLocalStorage, setLocalStorage } from "../hooks/appVariables";

const GreetingModal = () => {
    // Retrieve the showOnStart setting from localStorage (default to true if not present)
    const showOnStart = getLocalStorage(process.env.REACT_APP_SHOW_STARTUP) !== "false";

    const [modal, setModal] = useState(showOnStart);
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    const toggle = () => setModal(!modal);

    const handleCheckboxChange = (e) => {
        setDoNotShowAgain(e.target.checked);
    };

    const handleCloseModal = () => {
        if (doNotShowAgain) {
            setLocalStorage(process.env.REACT_APP_SHOW_STARTUP, "false");
        } else {
            setLocalStorage(process.env.REACT_APP_SHOW_STARTUP, "true");
        }
        setModal(false);
    };

    // Ensure modal stays hidden if 'do not show again' was previously checked
    useEffect(() => {
        if (!showOnStart) {
            setModal(false);
        }
    }, [showOnStart]);

    return (
        <>
            {modal && (
                <Modal isOpen={modal} toggle={toggle} size="xl" centered={true}>
                    <ModalHeader toggle={toggle}>Welcome to the New Maintel Portal!</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col className='display-8'>
                                <p>We’re excited to unveil your enhanced Maintel Portal, featuring a fresh design and new features!</p>
                                <p>Watch the demo video below to quickly discover the updates and get started.</p>
                                <p>Click play and enjoy the new experience!</p>
                                <div
                                    style={{
                                        position: 'relative',
                                        paddingBottom: '56.25%', // 16:9 aspect ratio
                                        height: 0,
                                        overflow: 'hidden',
                                        maxWidth: '100%',
                                        background: '#000',
                                    }}
                                >
                                    <iframe
                                        src="https://www.youtube-nocookie.com/embed/YyFH3VamGQ4?si=1np46UKCCZSQKEiA&rel=0"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin"
                                        allowFullScreen
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            border: 0,
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: "space-between" }}>
                        <FormGroup check style={{ display: "flex" }}>
                            <Input
                                type="checkbox"
                                id="chkDoNotShow"
                                checked={doNotShowAgain}
                                onChange={handleCheckboxChange}
                            />
                            <Label check for='chkDoNotShow' style={{ marginBottom: "0" }} className="ms-3">
                                Do not display this notification again.
                            </Label>
                        </FormGroup>
                        <Button className='no-print' color="primary" onClick={handleCloseModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}

export default GreetingModal;
