import CryptoJS from 'crypto-js';
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY

const encryptText = (data) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  return encrypted;
}

const decryptText = (encrypted) => {
  const decrypted = CryptoJS.AES.decrypt(encrypted, encryptionKey).toString(CryptoJS.enc.Utf8);
  return decrypted;
}

export const setLocalStorage = (key, value) => {
  if (!(value === undefined || value === null)) {
    window.localStorage.setItem(key, encryptText(value));
  }
};

export const getLocalStorage = (key) => {
  const lStoreItem = window.localStorage.getItem(key);
  if (lStoreItem === null) {
    return "";
  }
  var objText = decryptText(lStoreItem);
  return JSON.parse(objText);
};

