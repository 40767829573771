import { client } from "../../../api/client"
import { getLocalStorage } from '../../../hooks/appVariables'

export const updatePermissionChange = async (emailAddress, hasPermissionChange) => {
    if (process.env.REACT_APP_USELOCALDATA === "true") { 
        return 200
    } else {
        const payload = {emailAddress: emailAddress, hasPermissionChange: hasPermissionChange}
        const responseUsers = await client.put("users/users/permissionchange", JSON.stringify(payload))
        return responseUsers.data
    }
};