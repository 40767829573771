import { useState, createContext, useContext } from 'react';

const ConfirmationContext = createContext();

export const useConfirmation = () => {
    return useContext(ConfirmationContext);
};

export const ConfirmationProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => { });
    const [onCancel, setOnCancel] = useState(() => () => { });

    const confirm = (message, onConfirmCallback, onCancelCallback) => {
        setMessage(message);
        setOnConfirm(() => () => {
            onConfirmCallback();
            setIsVisible(false);
        });
        setOnCancel(() => () => {
            onCancelCallback && onCancelCallback();
            setIsVisible(false);
        });
        setIsVisible(true);
    };

    const handleConfirm = () => {
        onConfirm();
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <ConfirmationContext.Provider value={{ confirm, handleConfirm, handleCancel, isVisible, message }}>
            {children}
        </ConfirmationContext.Provider>
    );
};
