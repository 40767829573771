import { client } from "../../../api/client"
export const fetchUser = async (email) => {
    if (process.env.REACT_APP_USELOCALDATA === "true") { 
        return require('./_local/_userData.json')
    } else {

        const payload = JSON.stringify(
            {
                "emailAddress": email
            }
        )

        const responseUser = await client.post("users/users/fetchUser", payload)
        return responseUser.data
    }
};