import { create } from 'zustand'
import zukeeper from 'zukeeper'

import { getLocalStorage, setLocalStorage } from './hooks/appVariables'
import { FetchAuthenticatedUser } from './features/auth/authData'
import { fetchUser } from './features/user/data/userData'

const useStore = create(zukeeper((set, get) => ({
    email: '',
    setEmail: async () => {
        const email = await FetchAuthenticatedUser()
        set(() => ({ email }));
    },
    removeEmail: () => set(() => ({ email: '' })),

    user: {
        id: -1,
        firstName: '',
        lastName: '',
        emailAddress: '',
        avatar: ''
    },
    setUser: async () => {
        const user = await fetchUser(get().email)
        const currentUser = getLocalStorage(process.env.REACT_APP_USER_KEY)
        if (currentUser.length === 0) {
            setLocalStorage(process.env.REACT_APP_USER_KEY, user)
        }
        set(() => ({ user }));
    },
    removeUser: () => set(() => ({ user: {} })),
    removeCurrentUser: () => { clearLocalStorage() },

    hideBanner: false,
    setHideBanner: (value) => set(() => ({ hideBanner: value })),

})))

function clearLocalStorage() {
    localStorage.removeItem(process.env.REACT_APP_USER_KEY);
    localStorage.removeItem(process.env.REACT_APP_COMPANY_KEY);
    localStorage.removeItem(process.env.REACT_APP_IMPERSONATE_KEY);
  }

window.store = useStore;

export default useStore;