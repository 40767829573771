import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { Form } from 'react-router-dom'
import { Button, Input, Label, Col, FormGroup, FormFeedback, Container, Row, Card, CardBody, CardFooter, ModalBody, ModalFooter } from 'reactstrap'
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { fetchBannerByType } from "../../../data/campaigns/data/bannerByTypeData";
import { saveBanner } from '../data/saveBanner';
import { toast } from 'react-toastify'
import htmlToDraft from "html-to-draftjs";
import createVideoPlugin from "draft-js-video-plugin";
import { useTheme } from '../../../hooks/useTheme';

const BannerEditor = () => {
    const theme = useTheme();

    const videoPlugin = createVideoPlugin();

    const { types } = videoPlugin;
    const currentDate = new Date()
    const currentTimestamp = Date.parse(currentDate)
    const { data: welcomeBanner } = useQuery(['welcomeBanner', currentTimestamp], () => fetchBannerByType('welcome'), { refetchOnWindowFocus: false })

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editorLoaded, setEditorLoaded] = useState(false)
    const [bannerTitle, setBannerTitle] = useState()

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
    }

    const saveCurrentBanner = async () => {

        try {

            // The editor might contain a link to a src document for the iFrame - eg:-
            // https://www.youtube-nocookie.com/embed/frgNrT-OlD8?si=QKpTYo-ooSk6Y5Z1
            // It might, alternatively, contain formatted HTML
            // To allow for this, we check the plain text value in the editor. If it
            // starts with http - ie, is a URL - we just save it as plain text, 
            // otherwise we save it as HTML
            let bannerHtml = editorState.getCurrentContent().getPlainText("\u0001")
            if (bannerHtml.indexOf("http") !== 0) {
                bannerHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            }

            let bannerSaveHtml = {
                "bannerHtml": bannerHtml,
                "bannerTitle": bannerTitle,
                "bannerType": "welcome"
            }
            const result = await saveBanner(bannerSaveHtml)
            if (result.status === 200) {
                toast.success("The banner was successfully uploaded.")
            } else {
                toast.error("Unable to upload the banner.")
                console.error("Unable to upload the banner. Post save returned status: ", result.status);
            }
        } catch (error) {
            toast.error("Unable to upload the banner.")
            console.error("Unable to upload the banner. Error: ", error);
        }
    }

    const onBannerTitleChange = (obj) => {
        setBannerTitle(obj.target.value)
    }

    useEffect(() => {

        const convertHtmlToDraftjs = (htmlValue) => {
            const blocksFromHtml = htmlToDraft(htmlValue, (nodeName, node) => {
                if (nodeName === "video") {
                    const entityConfig = {};
                    entityConfig.src = node.getAttribute
                        ? node.getAttribute("src") || node.src
                        : node.src;
                    entityConfig.alt = node.alt;
                    entityConfig.height = node.style.height;
                    entityConfig.width = node.style.width;
                    if (node.style.float) {
                        entityConfig.alignment = node.style.float;
                    }

                    const value = {
                        type: types.VIDEOTYPE, // should similar to videoPlugin.type if use @draft-js-plugins/video
                        mutability: "IMMUTABLE",
                        data: entityConfig
                    };
                    return value;
                }
            });

            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            );
            const editorValue = EditorState.createWithContent(contentState);

            return editorValue;
        };

        if (welcomeBanner && !editorLoaded) {

            setBannerTitle(welcomeBanner.bannerTitle)

            const currentDBEditorState = convertHtmlToDraftjs(welcomeBanner.bannerHtml);

            setEditorState(currentDBEditorState)

            setEditorLoaded(true)
        }
    }, [welcomeBanner, editorLoaded, types])

    return (
        <>
            <ModalBody>
                <Form className='px-3'>
                    <FormGroup row>
                        <Label
                            for="bannerTitleInput"
                            sm={2}
                        >
                            Header
                        </Label>
                        <Col>
                            <Input
                                id="bannerTitleInput"
                                maxLength="100"
                                placeholder='100 chars max'
                                name="bannerTitleInput"
                                onChange={onBannerTitleChange}
                                value={bannerTitle}
                                invalid={bannerTitle && bannerTitle.length === 0}
                            />
                            <FormFeedback invalid>
                                Please enter the banner header
                            </FormFeedback>
                        </Col>
                    </FormGroup>
                    <Label row>
                        Body
                    </Label>
                    <FormGroup row>
                        <Col sm={12}>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbar-style"
                                wrapperClassName="wrapper-style"
                                editorClassName="editor-style"
                                onEditorStateChange={onEditorStateChange}
                            />
                            {/* <textarea
                        disabled
                        value={}
                    /> */}

                        </Col>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col className="my-3 d-flex flex-row-reverse">
                        <Button className="ms-3" color="primary" onClick={saveCurrentBanner} title="Save the first and last name changes">
                            Save
                        </Button>
                    </Col>
                </Row>
            </ModalFooter>

        </>


    );
}

export default BannerEditor