import { lazy } from "react";
import { Link, Navigate, createBrowserRouter } from "react-router-dom";
import ContentManager from "../views/admin/ViewContentManager.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const ReducedLayout = lazy(() => import("../layouts/ReducedLayout.js"));
const HelpLayout = lazy(() => import("../layouts/HelpLayout.js"));

/****Views*****/
const Dashboard = lazy(() => import("../views/Dashboard.js"));

const Quotes = lazy(() => import("../views/quotesAndProjects/Quotes.js"));
const Projects = lazy(() => import("../views/quotesAndProjects/Projects.js"));
const ViewProject = lazy(() => import("../views/quotesAndProjects/ViewProject.js"));
const ViewQuote = lazy(() => import("../views/quotesAndProjects/ViewQuote.js"));

const Tickets = lazy(() => import("../views/tickets/Tickets.js"));
const TicketDashboard = lazy(() => import("../views/tickets/TicketDashboard.js"));
const ViewTicket = lazy(() => import("../views/tickets/ViewTicket.js")); 

const ViewMaintenanceTicket = lazy(() => import("../views/tickets/ViewMaintenanceTicket.js"));

const SiteHealth = lazy(() => import("../views/sites/SiteHealth.js"));
const MaintenanceTickets = lazy(() => import("../views/tickets/MaintenanceTickets.js"));
const ViewSite = lazy(() => import("../views/sites/ViewSite.js")); 

const MyServices = lazy(() => import("../views/services/MyServices.js"));
const MobileReports = lazy(() => import("../views/services/MobileReports.js"));
const UCAnalytics = lazy(() => import("../views/services/UCAnalytics.js"));

const ViewContact = lazy(() => import("../views/ViewContact.js"));
const ViewResource = lazy(() => import("../views/ViewResource.js"));

const UserManagement = lazy(() => import("../views/admin/UserManagement.js"));
const ViewUser = lazy(() => import("../views/admin/ViewUser.js"));
const ViewCompany = lazy(() => import("../views/admin/ViewCompany.js"));

const Security = lazy(() => import("../views/admin/Security.js"));
const AdminCenter = lazy(() => import("../views/admin/AdminCenter.js"));

const UCAManagement = lazy(() => import("../features/storm/components/UCAManagement.js"));
const UCAUserManagement = lazy(() => import("../features/storm/components/UCAUserManagement.js"));
const UCACompanyUpdate = lazy(() => import("../features/storm/components/UCACompanyUpdate.js"));

const ContextManagement = lazy(() => import("../features/context/components/ContextManagement.js"));
const TraverseManagement = lazy(() => import("../features/traverse/components/TraverseManagement.js"));
const AdditionalCompanyContactManagement = lazy(() => import("../features/additional-company-contacts/components/AdditionalCompanyContactManagement.js"));

const CompanyManagement = lazy(() => import("../features/companyManagement/components/CompanyManagement.js"));
const CompanyDetail = lazy(() => import("../features/companyManagement/components/CompanyDetail.js"));
const SiteDetail = lazy(() => import("../features/companyManagement/components/SiteDetail.js"));
const CustomerDetail = lazy(() => import("../features/companyManagement/components/CustomerDetail.js"));

const AllUsersManagement = lazy(() => import("../features/global-users/components/AllUsersManagement.js"));

const NewCompany = lazy(() => import("../features/new-company/components/NewCompany.js"));

const About = lazy(() => import("../views/About.js"));

const Help = lazy(() => import("../views/help/HelpCentre.js"));
const ViewHelp = lazy(() => import("../views/help/ViewHelp.js"));

const ViewProfile = lazy(() => import("../views/ViewProfile.js"));

const Performance = lazy(() => import("../views/managed-services/Performance.js"));
const ViewIconCommunicate = lazy(() => import("../views/managed-services/ViewMaintelUCPrivatePlus.js"));
const ViewIconConnect = lazy(() => import("../views/managed-services/MaintelSecureConnect.js"));
const ViewIconNow = lazy(() => import("../views/managed-services/ViewMaintelUCPrivate.js"));

// /****Help Centre*****/
// const GettingStarted = lazy(() => import("../features/help-centre/components/GettingStarted.js"));

/*****Routes******/
///https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/#creating-protected-routes TIM TODO

const router = () => createBrowserRouter(
  [
    {
      path: "/",
      element: <FullLayout />,
      children: [
        { path: "/", title: "Dashboard", exact: true, element: <Navigate to="/dashboard" /> },
        { path: "dashboard", title: "Dashboard", exact: true, element: <Dashboard /> },

        { path: "quotes-and-projects", title: "Quotes and Projects", exact: true, element: <Navigate to="/quotes-and-projects/quotes" /> },
        { path: "quotes-and-projects/quotes", title: "Quotes", exact: true, element: <Quotes /> },
        { path: "quotes-and-projects/projects", title: "Projects", exact: true, element: <Projects /> },

        { path: "tickets", title: "Tickets", exact: true, element: <Navigate to="/tickets/alltickets" /> },
        { path: "tickets/alltickets", title: "Tickets", exact: true, element: <Tickets /> },
        { path: "tickets/ticketdashboard", title: "Ticket Dashboard", exact: true, element: <TicketDashboard /> },
        
        { path: "plannedmaintenance", title: "Planned Maintenance", exact: true, element: <MaintenanceTickets /> },

        { path: "sites", title: "Sites", exact: true, element: <SiteHealth /> },
        
        //{ path: "site/:id", title: "Sites", exact: true, element: <ViewSite />},
        { path: "services", title: "Services", exact: true, element: <Navigate to="/services/myservices" /> },
        { path: "services/myservices", title: "My Services", exact: true, element: <MyServices />},
        { path: "services/mobilereports", title: "Mobile Reports", exact: true, element: <MobileReports />},
        { path: "services/ucanalytics", title: "UC Analytics", exact: true, element: <UCAnalytics /> },

        { path: "managed-services", title: "Managed Services", exact: true, element: <Navigate to="/managed-services/performance" /> },
        { path: "managed-services/performance", title: "Performance", exact: true, element: <Performance />},

        { path: "admin", title: "Admin", exact: true, element: <Navigate to="/admin/user-management" /> },
        { path: "admin/user-management", title: "User Management", exact: true, element: <UserManagement /> },
        { path: "admin/company-management", title: "Company Management", exact: true, element: <Security /> },
        { path: "admin/admin-centre", title: "Advanced Settings", exact: true, element: <AdminCenter /> },

        { path: "admin/admin-centre/traverse-management", title: "Traverse Management", exact: true, element: <TraverseManagement />},
        { path: "admin/admin-centre/context-management", title: "Context Management", exact: true, element: <ContextManagement />},
        { path: "admin/admin-centre/uca-management", title: "UC Analytics Management", exact: true, element: <UCAManagement /> },
        { path: "admin/admin-centre/uca-management-user", title: "UC Analytics Management", exact: true, element: <UCAManagement selectedTab="1"/> },
        { path: "admin/admin-centre/uca-management-company", title: "UC Analytics Management", exact: true, element: <UCAManagement selectedTab="2"/> },
        { path: "admin/admin-centre/company-management", title: "Company Management", exact: true, element: <CompanyManagement /> },
        { path: "admin/admin-centre/all-user-management", title: "All Users Management", exact: true, element: <AllUsersManagement /> },
        { path: "admin/admin-centre/new-company", title: "Create Company", exact: true, element: <NewCompany /> },
        { path: "admin/admin-centre/additional-company-contacts", title: "Create Additional Company Contacts", exact: true, element: <AdditionalCompanyContactManagement /> },
        { path: "admin/admin-centre/content-manager", title: "Content Manager", exact: true, element: <ContentManager /> },

        { path: "about", title: "About", exact: true, element: <About /> },

        { path: "help", title: "Help Centre", exact: true, element: <Help /> },        
      ],
    },
    {
      path: "/",
      element: <ReducedLayout />,
      children: [
        { path: "managed-services/maintel-uc-private-plus", title: "Maintel UC Private+", exact: true, element: <ViewIconCommunicate />},
        { path: "managed-services/maintel-secure-connect", title: "Maintel Secure Connect", exact: true, element: <ViewIconConnect />},
        { path: "managed-services/maintel-uc-private", title: "Maintel UC Private", exact: true, element: <ViewIconNow />},

        { path: "sites/:id", title: "Site", exact: true, element: <ViewSite />},
        { path: "sites/:id/viewticket/:id", title: "Ticket", exact: true, element: <ViewTicket />},
        { path: "sites/:id/viewmaintenanceticket/:id", title: "Ticket", exact: true, element: <ViewMaintenanceTicket />},
        { path: "sites/:id/viewproject/:id", title: "Ticket", exact: true, element: <ViewProject />},
        { path: "sites/:id/viewticket/:id/viewcontact/:id", title: "Contact", exact: true, element: <ViewContact />},
        { path: "sites/:id/viewticket/:id/viewresource/:id", title: "Resource", exact: true, element: <ViewResource />},

        { path: "tickets/:id", title: "Ticket", exact: true, element: <ViewTicket />},
        { path: "tickets/:id/viewcontact/:id", title: "Contact", exact: true, element: <ViewContact />},
        { path: "tickets/:id/viewresource/:id", title: "Resource", exact: true, element: <ViewResource />},
        { path: "tickets/:id/viewproject/:id", title: "Project", exact: true, element: <ViewProject />},

        { path: "plannedmaintenance/:id", title: "Planned Maintenance", exact: true, element: <ViewMaintenanceTicket />},
        { path: "plannedmaintenance/:id/viewcontact/:id", title: "Contact", exact: true, element: <ViewContact />},
        { path: "plannedmaintenance/:id/viewresource/:id", title: "Resource", exact: true, element: <ViewResource />},

        { path: "admin/user-management/:id", title: "User", exact: true, element: <ViewUser />},
        { path: "admin/company-management/:companyid/user/:id", title: "User", exact: true, element: <ViewUser />},
        { path: "admin/company-management/:companyid/site/:id", title: "Site", exact: true, element: <SiteDetail />},
        { path: "admin/company-management/:companyid/customer/:id", title: "Site", exact: true, element: <CustomerDetail />},
        { path: "admin/company-management/:id/user", title: "Company", exact: true, element: <ViewCompany />},
        { path: "admin/company-management/:id/site", title: "Company", exact: true, element: <ViewCompany />},
        { path: "admin/company-management/:id/customer", title: "Company", exact: true, element: <CustomerDetail />},
        { path: "admin/company-management/:id", title: "Company", exact: true, element: <ViewCompany />},

        { path: "admin/admin-centre/uca-management-user/:id", title: "UC Analytics User", exact: true, element: <UCAUserManagement />},
        { path: "admin/admin-centre/uca-management-company/:id", title: "UC Analytics Company", exact: true, element: <UCACompanyUpdate />},

        { path: "admin/admin-centre/company-management/:id", title: "Company Management", exact: true, element: <CompanyDetail />},

        { path: "quotes-and-projects/projects/:id", title: "Project", exact: true, element: <ViewProject />},
        { path: "quotes-and-projects/quotes/:id", title: "Quotes", exact: true, element: <ViewQuote />},
        
        { path: "profile", title: "My Profile", exact: true, element: <ViewProfile /> },

        { path: "help/:id", title: "Help Centre", exact: true, element: <ViewHelp /> }
      ],
    },
    // {
    //   path: "/help",
    //   element: <HelpLayout />,
    //   children: [
    //     { path: ":id", title: "Getting Started", exact: true, element: <GettingStarted />},

        
    //   ],
    // },
    {
      path: "*",
      element: <NoMatch />,
    },
  ]
);

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default router;
