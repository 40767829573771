import { useState } from "react";
import { Button, Card, Modal, ModalBody } from "reactstrap";
import { getLocalStorage, setLocalStorage } from "../hooks/appVariables";

const CookieConfirmation = () => {
    const [modal, setModal] = useState(getLocalStorage("cookie-confirmation") === "" ? true : false);

    const toggle = () => {
        setLocalStorage("cookie-confirmation", false);
        setModal(false);
    }

    return (
        <Modal className="cookie-confirmation text-center mb-0"
            style={{ height: "200px" }}
            isOpen={modal}
            toggle={toggle}
            backdrop="static"
            fullscreen
            scrollable={false}>
            <ModalBody style={{ overflow: "hidden" }}>
                <div className="my-5">
                    <p>We use essential cookies to ensure our website works properly. By continuing to browse the site, you agree to our use of cookies.</p>
                    <Button color="primary" onClick={toggle}>Accept</Button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default CookieConfirmation;