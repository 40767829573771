import { client } from "../../../api/client"

export const fetchCompanies = async () => {
    //await new Promise(r => setTimeout(r, 5000));
    if (process.env.REACT_APP_USELOCALDATA === "true") { 
        return require('./_local/_companyData.json');
    } else {
        const response = await client.get("permissions/companies", )
        return response.data
    }
};